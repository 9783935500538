<template>
  <section
    id="popBarcode"
    :class="['barcode', 'v2', { pop: isPop }]"
    @click.prevent="closePopup"
  >
    <div class="popBody" @click.stop>
      <div class="header">
        <!-- ********** LineOA未驗證會員 ********** -->
        <div v-if="isLineCode && !userInfo.phoneVerified" class="content">
          <span class="title">未驗證會員</span>
          <span class="remark"
            >如欲使用點數或優惠券，請先至會員專區進行註冊</span
          >
        </div>
        <!-- <a href="">前往註冊</a> -->
        <!-- ********** 會員資訊 ********** -->
        <fragment v-else>
          <div>
            <!-- <img v-if="userImageUrl" :src="userImageUrl" /> -->
          </div>
          <span class="title">{{ username || '未驗證會員' }}</span>
          <!-- <span>會員等級</span> -->
        </fragment>
      </div>
      <div class="pages">
        <a
          :class="['page', { active: tab === 'member' }]"
          @click="tab = 'member'"
        >
          <span>會員條碼</span>
        </a>
        <a
          :class="['page', { active: tab === 'carrier' }]"
          @click="tab = 'carrier'"
        >
          <span>載具條碼</span>
        </a>
      </div>
      <div v-if="isLoading && tab === 'member' && showRefreshBtn" class="code">
        Loading...
      </div>
      <div v-else-if="tab === 'member' && subTab === 'qrcode'" class="code">
        <QrcodeVue
          v-if="qrcodeValue"
          class="qrcode"
          :value="qrcodeValue"
          size="190"
          level="H"
        />
      </div>
      <div v-else-if="tab === 'member' && subTab === 'code128'" class="code">
        <barcode
          v-if="barcodeValue"
          class="barcode"
          :value="barcodeValue"
          format="code128"
          width="2"
        >
          {{ barcodeValue }}
        </barcode>
      </div>
      <div v-else class="code">
        <!-- 原本是用v-if="userCardNo", 但不知道為什麼，會沒有更新到 -->
        <barcode
          v-if="userInfo?.cardNo"
          :value="userCardNo"
          format="code128"
          width="2"
        >
          {{ userCardNo }}
        </barcode>
        <!-- <div v-else-if="isLineCode">尚未綁定載具</div> -->
        <div v-else-if="!userInfo.phoneVerified">尚未綁定載具</div>
        <a v-else @click="goTo('/carrier/edit')"
          >尚未綁定載具，立即前往綁定
          <font-awesome-icon
            icon="fa-solid fa-angle-right"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <div class="action">
        <div v-show="tab === 'member'" class="left">
          <a
            v-if="barcodeInfo.length > 1"
            :class="{ active: subTab === 'code128' }"
            @click="subTab = 'code128'"
          >
            <font-awesome-icon
              icon="fa-solid fa-barcode"
              size="xl"
            ></font-awesome-icon>
          </a>
          <a
            v-if="barcodeInfo.length > 1"
            :class="{ active: subTab === 'qrcode' }"
            @click="subTab = 'qrcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-qrcode"
              size="xl"
            ></font-awesome-icon>
          </a>
        </div>
        <div v-show="tab === 'member' && showRefreshBtn" class="right">
          <span>{{ displayTimerToMinutes() }} 更新</span>
          <a @click.prevent="refreshMemberCode()"
            ><font-awesome-icon
              icon="fa-solid fa-arrows-rotate"
              size="lg"
            ></font-awesome-icon
          ></a>
        </div>
        <div v-show="tab !== 'member' && userInfo?.cardNo" class="right">
          <a @click.prevent="popDelete"> 
            <span>解除綁定</span>
          </a>
        </div>
      </div>
    </div>

     <!-- 訊息popup -->
    <section id="popNotice" class="pop notice" @click="popNoticeClose">
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <font-awesome-icon
              v-if="popupContent.type === 'success'"
              icon="fa-regular fa-circle-check"
              size="5x"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="popupContent.type === 'error'"
              icon="fa-regular fa-circle-xmark"
              size="5x"
            ></font-awesome-icon>
            <div class="orange" v-else>
              <font-awesome-icon
                icon="fa-solid fa-exclamation"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">{{ popupContent.title }}</span>
          <span>{{ popupContent.message }}</span>
        </div>
        <div class="buttons">
          <button v-if="popupContent.type === 'notice'" @click="popNoticeClose">
            <span>取消</span>
          </button>
          <button @click="popupContent.handleConfirmBtnClick">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import VueBarcode from 'vue-barcode';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'BarcodePopup',
  components: {
    barcode: VueBarcode,
    QrcodeVue,
  },
  props: {
    isPop: {
      type: Boolean,
      default: true,
    },
    isLineCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      memberCode: '',
      intervalId: '',
      defaultTimerSec: 300,
      timer: 300,
      tab: 'member',
      subTab: 'code128',
      isLoading: false,
      isProcessing: false,
      popupContent: {
        type: '',
        title: '',
        message: '',
        handleConfirmBtnClick: '',
      },
    };
  },
  mounted() {
    popBarcode.style.display = 'flex';
    if (this.needRefresh) {
      this.refreshMemberCode();
    }
    if (this.isPop) {
      document.body.style.overflow = 'hidden';
    }
  },
  beforeDestroy() {
    this.clearTimer();
    if (this.isPop) {
      document.body.style.overflow = 'scroll';
    }
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapState('portal', ['portalInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    username() {
      return this.userInfo?.name;
    },
    userImageUrl() {
      return this.userInfo?.picture;
    },
    userCardNo() {
      return this.userInfo?.cardNo ? this.userInfo.cardNo.trim() : null;
    },
    barcodeInfo() {
      return this.portalInfo?.barcode || [];
    },
    thirdPartyMemberId() {
      const key = this.barcodeInfo.find(code => code.type === 'Member-ID')?.key;
      if (!key) return;
      let ret = this.user.user._json;
      key.split('.').forEach(key => {
        if (!ret) return;
        ret = ret[key];
      });
      return typeof ret === 'string' ? ret : '';
    },
    barcode() {
      return this.barcodeInfo.find(code => code.format === 'code128');
    },
    qrcode() {
      return this.barcodeInfo.find(code => code.format === 'qrcode');
    },
    barcodeValue() {
      return this.barcode?.type === 'Member-ID'
        ? this.thirdPartyMemberId
        : this.memberCode;
    },
    qrcodeValue() {
      return this.qrcode?.type === 'Member-ID'
        ? this.thirdPartyMemberId
        : this.memberCode;
    },
    needRefresh() {
      return (
        !this.portalInfo?.barcode?.length ||
        this.portalInfo?.barcode?.find(code => code.type === 'One-Time-Key')
      );
    },
    showRefreshBtn() {
      if (!this.needRefresh) return;
      return this.subTab === 'qrcode'
        ? this.qrcode?.type !== 'Member-ID'
        : this.barcode?.type !== 'Member-ID';
    },
  },
  watch: {
    portalInfo: {
      handler() {
        this.subTab = this.portalInfo?.barcode?.length
          ? this.portalInfo.barcode[0].format
          : 'code128';
      },
      immediate: true,
    },
  },
  methods: {
    goTo(path) {
      let query = Object.assign(this.$route.query, {linecode: this.isLineCode});
      this.$router.push({ path, query: query });
    },
    hideIdentityNumber(str) {
      let pattern = /(^[a-zA-Z]{1})(\d{7})(\d{2})/g;
      return str.replace(pattern, function (match, start, middle, tail) {
        return start + '*'.repeat(middle.length) + tail;
      });
    },
    displayTimerToMinutes() {
      let mins = Math.floor(this.timer / 60);
      let secs = (this.timer % 60).toString().padStart(2, '00');
      return `${mins}:${secs}`;
    },
    getMemberCode() {
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/one-time-keys/pay`,
        method: 'POST',
      };
      return this.$http(config);
    },
    clearTimer() {
      clearInterval(this.intervalId);
      this.timer = this.defaultTimerSec;
      this.memberCode = '';
      this.intervalId = '';
    },
    periodRefreshMemberCode() {
      this.clearTimer();
      this.intervalId = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.refreshMemberCode();
        }
      }, 1000);
    },
    refreshMemberCode() {
      this.periodRefreshMemberCode();
      this.isLoading = true;
      this.getMemberCode()
        .then(memberCodeRes => {
          this.memberCode = memberCodeRes.data.oneTimeKey;
        })
        .catch(memberCodeErr => {
          console.log('memberCodeErr: ', memberCodeErr);
        })
        .finally(() => (this.isLoading = false));
    },
    closePopup() {
      popBarcode.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        this.$emit('closePopup');
        popBarcode.children[0].style.animationName = 'popup';
      }, 500);
    },
    popNotice(content) {
      if (!content.handleConfirmBtnClick)
        content.handleConfirmBtnClick = this.popNoticeClose;
      this.popupContent = content;
      popNotice.style.display = 'flex';
    },
    popNoticeClose() {
      popNotice.style.display = 'none';
    },
    popDelete() {
      this.popNotice({
        type: 'notice',
        title: '確定解除綁定載具？',
        message: '解除綁定後部分功能將無法正常使用',
        handleConfirmBtnClick: this.removeCarrier,
      });
    },
    removeCarrier() {
      this.isProcessing = true;
      this.popNoticeClose();
      let data = Object.assign({}, this.userInfo);
      data.cardNo = null;
      data.cardEncrypt = null;
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: 'PUT',
        data: data,
      };
      this.$http(config)
        .then(res => {
          console.log('res: ', res);
          this.popNotice({
            type: 'success',
            title: '載具解綁成功',
          });
          this.getUserData();
          // this.cardEncrypt = '';
        })
        .catch(err => {
          console.log('err: ', err);
          this.popNotice({
            type: 'error',
            title: '載具解綁失敗',
            message: '網路錯誤，請稍後重試',
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    callUserDataAPI() {
      // let data = Object.assign({}, this.userInfo);
      // data.cardNo = null;
      // data.cardEncrypt = null;
      // data.cardEncrypt = this.cardEncrypt;
      var config = {
        method: 'get',
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        // data
      };

      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        .then(res => {
          this.$store.commit('updateUserInfo', res.data);
          // this.userData = res.data;
          // this.linkedCarrier = res.data.cardNo;
          console.log(res.data);
        })
        .catch(err => {
          console.log('err: ', err);
          this.goTo('/error');
        });
    },
  },
};
</script>
